.container {
    max-width: 1300px !important;
    margin: 0 auto;
    position: relative;
}

/* margen del componente hero */
@media (max-width: 1300px) {
    .container {
        padding: 0 1.5rem;
    }
}