.contact-container {
    margin: 4rem 0;
    position: relative;
}

.contact-container h5 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 3rem;
}

.contact-container::after,
.contact-container::before {
    content: " ";
    width: 28rem;
    height: 28rem;
    border-radius: 28.125rem;
    background: #4c56e5;
    position: absolute;
    z-index: -1;
    filter: blur(200px);
    /* fuciona el fondo */
}

.contact-container::after {
    top: -3rem;
    left: 3rem;
}

.contact-container::before {
    background: #394e9f;
    /* fuciona el color con otro */
    bottom: 0rem;
    right: 0rem;
}

.contact-content {
    display: flex;
    gap: 3rem;
}

@media (max-width: 768px) {
    .contact-content {
        flex-direction: column-reverse;
    }

    .contact-container h5 {
        font-size: 1.3rem;
        margin-bottom: 2rem;
    }
}