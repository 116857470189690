/* tarjeta de información */
.skills-info-card {
  min-height: 24rem;
  border-radius: 0.65rem;
  border: 1.5px solid #2d3bdc;
  background: rgba(2, 1, 42, 0.398);
  backdrop-filter: blur(1rem);
}

.skills-info-card h6 {
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0.8rem 2rem;
  background: linear-gradient(180deg, #969fff 0%, #5f6ae6 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* muestra el fondo degradado en el texto */
  border-bottom: 1.5px solid #2d3bdc;
}

.skills-info-content {
  padding: 2rem;
}

.skill-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.skill-info p {
  font-size: 1rem;
  font-weight: 500;
}

.skill-info .percentage {
  color: hsl(218, 100%, 62%);
}

/* lineas de progreso porcentual */
.skill-progress-bg {
  width: 100%;
  height: 0.5rem;
  background: #2e3368;
  border-radius: 0.5rem;
  margin: 1rem 0;
  overflow: hidden;
}

.skill-progress {
  width: 0%;
  height: 0.5rem;
  background: linear-gradient(90deg, #94affe -50%, #505cff 50%);
  border-radius: 0.5rem;
  transition: all 0.5s ease-in-out; /* efecto de transición */
}


@media (max-width: 1024px) {
  .skills-info-card h6 {
    font-size: 1.1rem;
  }

  .skill-info p {
    font-size: 0.9rem;
  }

  .skills-info-content {
    padding: 1.5rem;
  }
}
